import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";

export default function Enga6Page() {
  return (
    <>
      <article class="page-engagement page-engagement-6">
        <FirstScreen
          visual="/images/engagements/page-engagement-6-visual.jpg"
          visualMobile="/images/engagements/page-engagement-6-visual-mobile.jpg"
          title="La mobilité douce"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-espaces-exterieurs-vegetalises.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-respect-de-la-biodiversite.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
        </section>

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-respect-de-la-biodiversite.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est favoriser les mobilités
            douces en intégrant dans toutes nos résidences des locaux vélo
            adaptés et sécurisés, ainsi que des stations de gonflage et de
            réparation.
          </p>
        </div>

        <div class="media-part">
          <picture class="media-part infography">
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-6-infographie.jpg",
                `size:1170:658/resizing_type:fill`,
              )}
              alt=""
              height="658"
              width="1170"
              loading="lazy"
            />
          </picture>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Un local à vélo équipé dans 100&nbsp;% de nos résidences</h3>
          <p>
            Afin de favoriser les mobilités douces, nous développons les locaux
            vélo avec systématiquement dans chaque résidence&nbsp;:
          </p>
          <ul>
            <li>
              un emplacement adapté facilitant l’utilisation quotidienne, et
              évitant la dégradation des parties communes&nbsp;;
            </li>
            <li>
              un système de fermeture sécurisé, et des dispositifs fixes
              individualisés, permettant de stabiliser et d'attacher les vélos
              par le cadre et ou par une roue&nbsp;;
            </li>
            <li>une station de gonflage/réparation et un point d’eau</li>
          </ul>
          <p class="excerpt">
            <strong>95 % de nos programmes</strong> sont à moins de 500m des
            transports.
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-6-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}
